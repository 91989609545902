import React from 'react';

import * as DesignSystem from 'src/components/design-system';
import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from '../../config/routes';

const getCreneauxLines = (creneaux) =>
  creneaux.map((c) => [
    c.intitule,
    c.jour,
    c.heure_debut,
    `${c.duree} minutes`,
    c.complet ? (
      <span className="text-red-500">Complet</span>
    ) : (
      'Places disponibles'
    ),
  ]);

const CreneauxTable = ({ creneaux = [] }) => (
  <DesignSystem.Table
    className="mt-4"
    headers={['', 'Jour', 'Horraire', 'Durée', 'Complet?']}
    lines={getCreneauxLines(creneaux)}
  />
);

const CreneauxTableWithData = () => {
  const { requestState } = useAxiosNow({
    url: routes.creneauxDispos(),
  });

  return (
    <DesignSystem.Loading requestState={requestState}>
      {(rs) => <CreneauxTable creneaux={rs.data.data} />}
    </DesignSystem.Loading>
  );
};

export default CreneauxTableWithData;
