import React from 'react';

import { convertDateISOToFrFull } from 'src/utils/date';

import { P } from 'src/components/layouts/content/theme';
import Table from 'src/components/design-system/table';

const exampleLines = (session) => [
  ['Adhésion', `${session.adhesion} euros`],
  ['Prix premier enfant', `${session.enfant1} euros`],
  ['Prix deuxième enfant', `${session.enfant2} euros`],
];

const Example = ({ data }) => {
  const session = data.sessions && data.sessions[0];
  return Boolean(session) ? (
    <>
      <P>
        Pour une famille de deux enfants de moins de 5 ans démarrant à la
        session du {convertDateISOToFrFull(session.date_debut_activite)}:
      </P>
      <Table
        className="mt-4"
        lines={exampleLines(session)}
        footers={[
          'Total',
          `${session.adhesion + session.enfant1 + session.enfant2} euros`,
        ]}
      />
    </>
  ) : (
    <div>
      Les informations concernant cette saison ne sont pas encore disponibles.
      Merci de revenir ultérieurement.
    </div>
  );
};

export default Example;
