import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import Link from 'src/components/design-system/link';
import SEO from 'src/components/seo';
import Tarifs from 'src/components/tarifs';
import CreneauxTable from 'src/components/tarifs/creneaux';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEO title="Inscriptions et tarifs" description="Tarifs de l'activité bébés nageurs de l'association l'enfant d'eau" mdxType="SEO" />
    <h2>{`Inscriptions et tarifs`}</h2>
    <h3>{`Déroulement de l'inscription`}</h3>
    <p>{`Les inscriptions se déroulent en deux étapes :`}</p>
    <ul>
      <li parentName="ul">{`Inscription sur le site web`}</li>
      <li parentName="ul">{`Validation de l'inscription`}</li>
    </ul>
    <p>{`Le paiement se fait directement sur le site web.`}</p>
    <p>{`L'inscription est validée si :`}</p>
    <ul>
      <li parentName="ul">{`le créneau correspond à l'âge de l'enfant au moment du début de la session`}</li>
      <li parentName="ul">{`le créneau est disponible – l'association est dépendante de la mise à
disposition des créneaux par la mairie de Paris`}</li>
      <li parentName="ul">{`les justificatifs de vaccination obligatoires ont été présentés`}</li>
      <li parentName="ul">{`un certificat médical d’aptitude aux activités aquatiques de moins de 3 mois
est présenté`}</li>
      <li parentName="ul">{`les justificatifs de réduction sont remis à l'association`}</li>
    </ul>
    <p>{`Dans le cas contraire, votre inscription sera annulée et votre place libérée
selon les dispositions de l’article 5 du `}<a parentName="p" {...{
        "href": "/reglementinterieurv2.3.pdf"
      }}>{`règlement intérieur`}</a>{`.
Le montant de l’adhésion est obligatoirement déduit du remboursement. Au-delà de 2 séances
sans présentation des documents, l’accès au bassin sera interdit à l’adhérent.`}</p>
    <h3>{`Créneaux`}</h3>
    <p>{`Les séances sont organisés en créneaux par tranche d'age. Voici les créneaux ouverts pour la saison:`}</p>
    <CreneauxTable mdxType="CreneauxTable" />
    <h3>{`Tarifs`}</h3>
    <p>{`L'association pratique un tarif dégressif en fonction du nombre d'enfants dans
la famille. L’association propose un tarif réduit (60% du tarif plein), sous
condition de ressources ou pour les enfants en situation de handicap. Nous
invitons les parents d’enfants en situation de handicap à nous contacter par
email s’ils ont des demandes particulières (choix de l’horaire notamment), nous
tâcherons d’y répondre du mieux possible.`}</p>
    <Tarifs mdxType="Tarifs" />
    <h3>{`Réduction`}</h3>
    <p>{`Le calcul est basé sur celui du quotient familial de la Caisse d’Allocations
Familiales de Paris. Il est détaillé ci-dessous. Vous pouvez retrouver le mode
de calcul de ce quotient sur le
`}<a parentName="p" {...{
        "href": "http://www.caf.fr/ma-caf/caf-du-calvados/petite-enfance/comprendre-le-calcul-du-quotient-familial-pour-les-aides-d-action-sociale"
      }}>{`site de la CAF`}</a>{`.`}</p>
    <p>{`Ce tarif réduit (60% du tarif plein) est proposé à toutes les familles dont le
quotient familial est inférieur ou égal à 548 €. Un justificatif, que vous pouvez
obtenir auprès de votre caisse d’allocations familiales, devra nous être fourni
afin de bénéficier de cette réduction.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      