import React from 'react';

import { useAxiosNow } from 'src/hooks/axios/useAxios';
import routes from 'src/config/routes';
import Loading from 'src/components/design-system/loading';

import TableCreneau from 'src/components/tarifs/table';
import Example from 'src/components/tarifs/example';

const Tarifs = () => {
  const { requestState } = useAxiosNow({
    url: routes.saisonProchaine(),
  });

  return (
    <Loading
      requestState={requestState}
      errorComponent={() => (
        <p className="font-normal my-4 text-center">
          Les tarifs ne sont pas encore disponibles. Cette section sera mise à
          jour quand les inscriptions seront ouvertes.
        </p>
      )}
    >
      {(rs) => {
        const data = rs.data;
        if (data.data) {
          return (
            <>
              <TableCreneau data={data.data} />
              <Example data={data.data} />
            </>
          );
        }
      }}
    </Loading>
  );
};

export default Tarifs;
