import React from 'react';

import Table from 'src/components/design-system/table';
import { convertDateISOToFrFull } from 'src/utils/date';

const getLines = (sessions = []) =>
  sessions.map((s) => [
    convertDateISOToFrFull(s.date_debut_activite),
    s.adhesion,
    s.enfant1,
    s.enfant2,
    s.enfant3,
  ]);

const TableCreneau = ({ data }) => (
  <Table
    className="mt-4"
    headers={[
      'Début de la session',
      'Prix adhésion',
      '1er enfant',
      '2ème enfant',
      '3ème enfant',
    ]}
    lines={getLines(data.sessions)}
  />
);

export default TableCreneau;
